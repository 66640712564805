import { css } from 'styled-components';

export const innerBorder = (
  style: string,
  position?: 'all' | 'top' | 'left' | 'bottom' | 'right',
  inheritColor = false
) => {
  const borderProperty = position && position !== 'all' ? `border-${position}` : 'border';

  return css`
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: inherit;
      ${borderProperty}: ${style || 'none'};
      ${inheritColor ? 'border-color: inherit;' : undefined}
      pointer-events: none;
      user-select: none;
    }
  `;
};

export const horizontalSpace = (spaceValue: number | string = 8) => {
  const space = typeof spaceValue === 'number' ? `${spaceValue}px` : spaceValue;

  return css`
    > * {
      &:not(:last-child) {
        margin-right: ${space} !important;
      }
    }
  `;
};

export const verticalSpace = (spaceValue: number | string = 8) => {
  const space = typeof spaceValue === 'number' ? `${spaceValue}px` : spaceValue;

  return css`
    > * {
      &:not(:last-child) {
        margin-bottom: ${space} !important;
      }
    }
  `;
};

export const horizontalSpaceLineDivider = ({
  space: spaceValue = 8,
  lineSize = 1,
  lineColor = '#D2D3D9',
}: {
  space?: string | number;
  lineSize?: number;
  lineColor?: string;
}) => {
  const space = typeof spaceValue === 'number' ? `${spaceValue}px` : spaceValue;

  return css`
    > * {
      &:not(:last-child) {
        position: relative;
        margin-right: calc(2 * ${space});

        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: -${space};
          border-right: ${lineSize}px solid ${lineColor};
        }
      }
    }
  `;
};

export const verticalSpaceLineDivider = (configs: {
  space?: string | number;
  lineSize?: number;
  lineColor?: string;
} | false) => {
  if (!configs) {
    return css`
      > * {
        &:not(:last-child) {
          position: relative;
          margin-bottom: 0;

          &:after {
            content: none;
          }
        }
      }
    `;
  }

  const {
    space: spaceValue = 8,
    lineSize = 1,
    lineColor = '#D2D3D9',
  } = configs;
  const space = typeof spaceValue === 'number' ? `${spaceValue}px` : spaceValue;

  return css`
    > * {
      &:not(:last-child) {
        position: relative;
        margin-bottom: calc(2 * ${space});

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -${space};
          border-bottom: ${lineSize}px solid ${lineColor};
        }
      }
    }
  `;
};

type RowGap = number | string;
type ColumGap = number | string;

export const flexGap = (
  gapValue: number | string | [RowGap, ColumGap] = 8,
  flexDirection: 'row' | 'column' = 'row'
) => {
  if (Array.isArray(gapValue)) {
    const rowGap = typeof gapValue[0] === 'number' ? `${gapValue[0]}px` : gapValue[0];
    const columnGap = typeof gapValue[1] === 'number' ? `${gapValue[1]}px` : gapValue[1];

    return css`
      margin-bottom: -${columnGap};
      margin-right: -${rowGap};

      > * {
        margin-bottom: ${columnGap} !important;
        margin-right: ${rowGap} !important;
      }
    `;
  }

  const gap = typeof gapValue === 'number' ? `${gapValue}px` : gapValue;

  if (flexDirection === 'column') {
    return verticalSpace(gap);
  }

  return horizontalSpace(gap);
};

export const scrollBarStyles = (
  styles: {
    size?: number;
    trackColor?: string;
    thumbColor?: string;
    thumbSize?: number;
  } = {}
) => {
  const { size = 12, trackColor = '#FFFFFF', thumbColor = '#D2D3D9', thumbSize = 4 } = styles;

  return css`
    &::-webkit-scrollbar {
      width: ${size}px;
      height: ${size}px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${trackColor};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${thumbColor};
      border: ${(size - thumbSize) / 2}px solid ${trackColor};
    }
  `;
};

export const scrollBarStylesGeneral = (thumbColor = '#D2D3D9') => {
  return scrollBarStyles({
    size: 12,
    trackColor: '#FFFFFF',
    thumbColor,
    thumbSize: 4,
  });
};

export const scrollbarStylesHidden = () => {
  return css`
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  `;
};

export const toPx = (value) => (value ? `${Math.round(value)}px` : undefined);
